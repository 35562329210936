<template>
    <div class="receipt-page">
        <layout :header="$t('RECEIPT')" @goback="$router.push('/History')">
            <template v-slot:content>
                <v-col class="card-Receipt" cols="12">
                    <v-row class="Receipt-item px-2 py-3" dense>
                        <v-col cols="12" align-self="center">
                            <div class="header-Receipt">
                                {{$i18n.locale == 'th' ? 'ใบเสร็จสำหรับ': 'RECEIPT FOR'}}
                            </div>
                            <div class="title-Receipt">
                                {{$i18n.locale == 'th' ? historyById.coupon_id.name || '' : historyById.coupon_id.name_en || ''}}
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="Receipt-item px-2 py-3 mt-5" dense>
                        <v-col cols="12" class="box-detail" align-self="center">
                            <div>
                                <img src="/Image/AMOUNT@2x.png" alt="">
                            </div>
                            <div class="header-Receipt">
                                {{$i18n.locale == 'th' ? 'จำนวน': 'AMOUNT'}}
                            </div>
                            <div class="title-Receipt">
                                {{formatMoney(historyById.price, historyById.type != 'jfin' ? 2 : 7)}} 
                                <span v-if="historyById.type != 'jfin'">{{$i18n.locale == 'th' ? 'บาท': 'THB'}}</span>
                                <span v-else>JFIN</span>
                            </div>
                        </v-col>
                        <v-divider class="mx-4 my-4"></v-divider>
                        <v-col cols="12" class="box-detail" align-self="center">
                            <div>
                                <img src="/Image/date@2x.png" alt="">
                            </div>
                            <div class="header-Receipt">
                                {{$i18n.locale == 'th' ? 'วันที่': 'DATE'}}
                            </div>
                            <div class="title-Receipt">
                                {{DateTime(historyById.purchased_at.iso)}}
                            </div>
                        </v-col>
                        <v-divider class="mx-4 my-4"></v-divider>
                        <v-col cols="12" class="box-detail" align-self="center">
                            <div>
                                <img src="/Image/ORDER_NUMBER@2x.png" alt="">
                            </div>
                            <div class="header-Receipt">
                                {{$i18n.locale == 'th' ? 'หมายเลขใบเสร็จ': 'ORDER NUMBER'}}
                            </div>
                            <div class="title-Receipt">
                                {{historyById.order_no || '-'}}
                            </div>
                        </v-col>
                         <v-divider class="mx-4 my-4"></v-divider>
                        <v-col cols="12" class="box-detail pb-2" align-self="center">
                            <div>
                                <img src="/Image/ORDER_NUMBER@2x.png" alt="">
                            </div>
                            <div class="header-Receipt">
                                {{$i18n.locale == 'th' ? 'สถานะการจ่ายเงิน': 'Paying Status'}}
                            </div>
                            <div class="title-Receipt" style="text-transform: uppercase;">
                                {{historyById.status}}
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
        </layout>
    </div>
</template>

<script>
    import Layout from "../../components/layout_1";
    export default {
        name: "History",
        components: {
            layout: Layout,
        },
        computed: {
            historyById() {
                return this.$store.getters.historyById(this.$route.params.id);
            }
        }
    };
</script>

<style lang="scss">
    @import '@/assets/scss/color.scss';

    .receipt-page {
        height: 100%;

        .card-history:not(:last-child) {
            padding-bottom: 10px;
        }

        .card-Receipt {
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 20px;

            .Receipt-item {
                width: 100%;
                border: 1px solid $accent-color;
                border-radius: 12px;
                background-color: white;
                max-width: 600px;
                margin: 0 auto;

                .box-detail {
                    display: flex;
                    flex: row;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    align-items: flex-start;
                    padding: 0px 10px;

                    >div:nth-child(1) {
                        display: flex;
                        justify-content: center;
                        width: 15%;
                        padding-right: 5px;

                        img {
                            margin: 0 auto;
                            width: 80%;
                            max-width: 40px;
                            height: auto;
                        }
                    }

                    >div:nth-child(2) {
                        width: 85%;
                        padding-top: 8px;
                    }

                    >div:nth-child(3) {
                        width: 85%;
                        line-height: 0.8;
                    }
                }

                .title-Receipt {
                    font-size: 17px;
                    font-weight: 800;
                }

                .header-Receipt {
                    font-weight: 800;
                    color: #969696;
                    font-size: 11px;
                }
            }
        }

    }
</style>